import LaLigaTechLogo from '../laLigaTechLogoSmall.png';
import { Button } from '@mui/material';
import './logInPage.css';

const loginUrl = () => {
    const domain = window.location.hostname; 
    if (domain.includes('localhost')) {
        //16/08/2024 SE CAMBIA LA URL DESTINO HACÍA DV EN VEZ DE TS  
        //return 'https://lltb2cbolts.b2clogin.com/bol-b2c-ts.llt-services.com/oauth2/v2.0/authorize?p=B2C_1_Poc_BlackList_Test_signin&client_id=3869d319-803b-46af-8f14-1e0cba446b4f&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=openid&response_type=id_token&prompt=login';        
        return 'https://lltb2cboldv.b2clogin.com/bol-b2c-dv.llt-services.com/oauth2/v2.0/authorize?p=B2C_1_Auth_DV_Signin_BlackList&client_id=396c770c-7488-4b08-97ab-9c9d5eb9fa18&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=openid&response_type=id_token&prompt=login';                
    }
    //16/08/2024 SE CAMBIA LA URL DESTINO HACÍA DV EN VEZ DE TS  
    //return `https://lltb2cbolts.b2clogin.com/bol-b2c-ts.llt-services.com/oauth2/v2.0/authorize?p=B2C_1_Poc_BlackList_Test_signin&client_id=3869d319-803b-46af-8f14-1e0cba446b4f&nonce=defaultNonce&redirect_uri=https%3A%2F%2F${domain}%2F&scope=openid&response_type=id_token&prompt=login`;    
    return `https://lltb2cboldv.b2clogin.com/bol-b2c-dv.llt-services.com/oauth2/v2.0/authorize?p=B2C_1_Auth_DV_Signin_BlackList&client_id=396c770c-7488-4b08-97ab-9c9d5eb9fa18&nonce=defaultNonce&redirect_uri=https%3A%2F%2F${domain}%2F&scope=openid&response_type=id_token&prompt=login`;
};
const LogInPage = ({ notAllowed }) => {
    return <div className={'logInPageContainer'}>
        <header>
            <span>{'BLACKLIST'}</span>
            <img id={'LLT-logo'} src={LaLigaTechLogo} alt={'La Liga Tech'}/>
        </header>
        <div className={'logInPageContent'}>
            <Button style={{backgroundColor: "#B0DA00"}} variant={'contained'} onClick={() => { window.location = loginUrl(); }} >
                {'Log In'}
            </Button>
            {notAllowed && <span id={'notAllowedMessage'}>{'La cuenta con la que intenta loguearse no tiene acceso a la aplicacion.'}</span>}
        </div>
    </div>
}

export default LogInPage;